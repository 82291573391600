.chatContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}

.messagesContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  padding: 20px;
  row-gap: 10px;
}

.inputContainer {
  padding: 20px;
  width: 100%;
}

.loadingMessage {
  margin-left: 70px;
  border-radius: 24px;
  align-self: flex-start;
}

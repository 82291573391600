.boldText {
  font-weight: bold;
}

.formattedMessage table,
tr,
td,
th {
  border: 1px solid #555;
  border-collapse: collapse;
}

.formattedMessage table {
  margin: 10px 0;
}

.formattedMessage tr,
td,
th {
  padding: 6px 10px;
  text-align: left;
}

.container {
  width: 100%;
  height: 50px;
  border-radius: 26px;
  background-color: white;
  padding: 10px;
  display: flex;
  column-gap: 16px;
}

.input {
  width: 100%;
  padding: 10px 15px; /* Adding padding */
  border: none; /* Adding a subtle border */
  font-size: 16px; /* Adjusting font size */
  outline: none; /* Removing the outline */
}

.iconContainer {
  cursor: pointer;
}

.disabled {
  cursor: default;
}

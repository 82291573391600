.container {
  width: max-content;
  height: 100%;
  border-right: 1px #d6d6d6 solid;
}

.closed {
  width: 50px;
  padding: 10px;
}

.listIcon {
  cursor: pointer;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  padding: 10px 5px;
  user-select: none;
  width: fit-content;
}

.listIcon:hover {
  background-color: rgb(201, 201, 201);
}

.opened {
  height: 100%;
  display: flex;
}

.opened .navbarContainer {
  width: 250px;
  padding: 10px;
  background-color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.opened .backdrop {
  display: none;
}

.mobileFiller {
  width: 0;
}

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
}

.closeIcon {
  cursor: pointer;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  padding: 7px;
  width: fit-content;
  user-select: none;
}

.closeIcon:hover {
  background-color: rgb(201, 201, 201);
}

@media (max-width: 480px) {
  .opened {
    top: 0;
    position: fixed;
    width: 100%;
  }

  .opened .backdrop {
    flex-grow: 1;
    background-color: black;
    opacity: 0.5;
    display: block;
  }

  .mobileFiller {
    width: 50px;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f4f6;
  column-gap: 8px;
  border-radius: 16px;
  padding: 8px 10px;
  cursor: pointer;
  width: 100%;
}

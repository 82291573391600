.page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.inputContainer {
  margin-bottom: 20px;
}

.inputContainer label {
  display: block;
  margin-bottom: 5px;
}

.inputField {
  width: 300px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.submitButton {
  padding: 10px 20px;
  background-color: #4d4d4d;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100px;
}

.submitButton:hover {
  background-color: #747474;
}

.submitButton:disabled {
  background-color: #ccc; /* Fill in with your desired color */
  cursor: default; /* Optionally, change cursor to indicate disabled state */
  /* Add more styles for the disabled state if needed */
}

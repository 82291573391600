* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "TT Commons", "Roboto", "Normal", "Arial", sans-serif;
  scrollbar-width: thin; /* Make the scrollbar thin */
  scrollbar-color: #888 #f1f1f1; /* Thumb color and track color */
}

/* Webkit Browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
  height: 12px; /* Height of the scrollbar (horizontal bar) */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the track */
  border-radius: 10px; /* Round the track */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the thumb */
  border-radius: 10px; /* Round the thumb */
  border: 3px solid #f1f1f1; /* Padding around thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the thumb on hover */
}

/* Hide scrollbar buttons */
::-webkit-scrollbar-button {
  display: none; /* Hide the scrollbar buttons */
}

.messageContainer {
  display: flex;
  column-gap: 16px;
  max-width: 100%;
}

.imageContainer {
  padding-top: 8px;
}

.assistantAlignment {
  align-self: flex-start;
}

.userAlignment,
.systemAlignment {
  align-self: flex-end;
}

.message {
  padding: 10px 20px;
  border-radius: 24px;
  overflow-x: auto;
}

.userMessage {
  background-color: white;
  align-self: flex-end;
}

.systemMessage {
  background-color: grey;
  color: white;
  align-self: flex-end;
}

.assistantMessage {
  align-self: flex-start;
}

.message .debuggingData {
  display: flex;
  font-size: 12px;
  color: #000000;
  margin-bottom: 12px;
  flex-direction: column;
}

.page {
  height: 100svh;
  width: 100%;
  display: flex;
  background-color: #f4f4f6;
}

.pageContent {
  height: 100%;
  flex-grow: 1;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  width: 90%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
  text-align: center;
  margin-bottom: 60px;
}

.logoContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 6px;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.link {
  padding: 10px 20px;
  background-color: #4d4d4d;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 120px;
  /* remove link effects*/
  text-decoration: none;
  display: inline-block;
  color: white;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
}

.link:hover {
  background-color: #747474;
}

.list {
  width: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 10px;
  height: 100%;
}

.dateGroup {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
}

.dateGroupTitle {
  padding: 0 2px;
  font-weight: bold;
}

.chatItem {
  padding: 4px;
  cursor: pointer;
  width: 100%;
  border-radius: 8px;
}

.chatItem:hover {
  background-color: #f4f4f6;
}

.selectedChatItem {
  cursor: default;
  background-color: #f4f4f6;
}

.header {
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 20px;
}

.linksContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 16px;
}

.linksContainer a {
  text-decoration: none; /* No underline */
  transition: color 0.3s, text-shadow 0.3s; /* Smooth transition */
}

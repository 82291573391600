.page {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pageContent {
  margin-bottom: 100px;
  align-items: center;
  display: flex;
  flex-direction: column;
  row-gap: 26px;
  width: 90%;
}

.header {
  font-size: 32px;
}

.inputContainer {
  width: 100%;
  max-width: 600px;
}

@media (max-width: 480px) {
  .page {
    padding-top: 40%;
    align-items: flex-start;
  }
}
